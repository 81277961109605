import { Aurelia, bindable, inject } from 'aurelia-framework';
import { AuthService, FetchConfig }  from 'aurelia-auth';
import { AppContainer }              from 'resources/services/app-container';
import { BaseViewModel }             from 'base-view-model';
import { CustomHttpClient }          from 'resources/services/custom-http-client';
import environment                   from '../../../config/environment.json';

@inject(Aurelia, AppContainer, AuthService, CustomHttpClient, FetchConfig)
export class ResetPassword extends BaseViewModel {

    /**
     * Form alert
     *
     * @type {{}}
     */
    @bindable alert = {};

    /**
     * Password field
     *
     * @type {string}
     */
    password = '';

    /**
     * Password confirmation field
     *
     * @type {string}
     */
    password_confirmation = '';

    /**
     * Token field
     *
     * @type {string}
     */
    token = '';

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     * @param authService
     * @param httpClient
     * @param fetchConfig
     */
    constructor(aurelia, appContainer, authService, httpClient, fetchConfig) {
        super(appContainer);

        this.aurelia     = aurelia;
        this.auth        = authService;
        this.httpClient  = httpClient;
        this.fetchConfig = fetchConfig;
        this.environment = environment;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        super.activate(params);

        this.token = params.token;

        this.configureHttpClient();
        this.clearInitialUrl();
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'login-container';
    }

    /**
     * Configures the HTTP client
     */
    configureHttpClient() {
        this.fetchConfig.configure();

        // configures aurelia-auth fetch client to include application locale
        this.fetchConfig.httpClient.configure(config => {
            config.withDefaults({
                headers: {
                    'Application-Locale': this.appContainer.i18n.getLocale(),
                },
            });
        });
    }

    /**
     * Clears any existing initial URL
     */
    clearInitialUrl() {
        let auth = this.aurelia.container.get(AuthService);
        auth.auth.setInitialUrl(null);
    }

    /**
     * Handles form submission
     */
    submit() {
        this.alert = {};

        if (this.validate()) {
            this.httpClient
                .post(`password-resets/reset/${ this.token }`, { password: this.password, password_confirmation: this.password_confirmation })
                .then(response => {
                    if (response.status === true) {
                        this.alert = this.alertMessage(response.status, this.appContainer.i18n.tr('text.you-can-now-login-with-your-new-password'), response.errors);

                        // automatically redirects to the login page
                        this.redirectToRoute('login', 2000);
                    } else {
                        let message = response.errors instanceof Array ? response.errors[0] : response.message;

                        this.alert = this.alertMessage(response.status, message);
                    }
                });
        }
    }

    /**
     * Validates the form
     *
     * @returns {boolean}
     */
    validate() {
        return this.password.length > 0 && this.password_confirmation.length > 0;
    }

}
